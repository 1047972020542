import React from "react"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Layout from "../components/layout"
import SeoComponent from "../components/seoComponent"
import styled from "styled-components"
import Container from "../components/Container"
import { Link } from "gatsby"
import Checked from "../images/checked.svg"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const SuccessPage = () => (
  <Layout>
    <Header />
    <SeoComponent title="Success" description="Contact form sent" />
    <Container>
      <FourInner>
        <img src={Checked} alt="success" />
        <h1>Success</h1>
        <p>We have received your message and will be in touch soon.</p>
        <TeamAllLink>
          <Link to="/">Back to Home</Link>
        </TeamAllLink>
      </FourInner>
    </Container>
    <Footer />
  </Layout>
)
export default SuccessPage

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const FourInner = styled.div`
  margin-top: 15vh;
  margin-bottom: 10vh;
  text-align: center;
  h1 {
    margin-bottom: 15px;
    font-size: 70px;
  }
  p {
    font-size: 25px;
    margin-bottom: 55px;
  }
  height: 40vh;
  img {
    width: 100px;
    height: auto;
  }
`
const TeamAllLink = styled.div`
  text-align: center;
  a {
    background-color: ${(props) => props.theme.green};
    padding: 12px 40px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    &:hover {
      background: ${(props) => props.theme.dark};
      color: ${(props) => props.theme.white};
      transform: scale(1.05);
    }
  }
`
